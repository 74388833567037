import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import {
    BaseFacet,
    BaseFacetService,
    IFacet
} from '../../common/facet';
import { filterToDate } from '../../common/util';

import { TranslationService } from '../../services/translation.service';
import { WorkspaceFilterService } from '../../services/workspace-filter.service';
import { WsFilterEvent } from '../../services/ws-filter-event';

import { JobFilterComponent } from '../job-filter.component';
import { JobLogicService } from '../job-logic.service';
import { JobService } from '../job.service';
import { JobTableOptions } from '../job-table-options';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Entity, Job, JobMaterial } from '../../common/types';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { takeUntil } from 'rxjs/operators';
import { DataContextService } from '@services/data-context.service';
import { arrowClockwise, brokenChain, chain, magnifier, viceVersaArrowsHorizontal } from '@icons';
import { SaveChangesService } from '@services/save-changes.service';
import { JobPharmaCoreService } from './services';
import { SettingService } from 'src/app/settings/setting.service';
import { IFacetSetting } from 'src/app/settings/facet-settings/facet-settings.interface';

@Component({
    selector: 'job-pharma-facet',
    templateUrl: './job-pharma-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class JobPharmaFacetComponent extends BaseFacet<Entity<Job>>
    implements OnInit, OnDestroy {
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier, viceVersaArrowsHorizontal };

    componentName = 'job-pharma';

    isCRO = false;
    isGLP = false;
    isCRL = false;

    jobDetailsOrder: any = {};
    JOB_DETAILS_CRO_ORDER = {
        jobID: 1,
        jobCode: 2,
        institutions: 3,
        researchDirector: 4,
        clientManager: 5,
        studyDirector: 6,
        permit: 7,
        permitOwner: 8,
        iacuc: 9,
        compliance: 10,
        status: 11,
        location: 12,
        study: 13,
        approvalNumber: 14,
        jobType: 15,
        jobSubtype: 16,
        imaging: 17,
        characteristics: 18,
        title: 19,
        description: 20,
        testArticles: 21,
        groups: 22,
        orderID: 23,
        linesTable: 24,
        lineDetailsTable: 25,
        jobReport: 26,
        standardPhrases: 27,
        projectImplantDate: 28,
        implantedDate: 29,
        projectedStartDate: 30,
        startDate: 31,
        durationDays: 32,
        endDate: 33,
        created: 34,
        files: 35,
        reports: 36,
    };
    JOB_DETAILS_NORMAL_ORDER = {
        jobID: 1,
        jobCode: 2,
        study: 3,
        title: 4,
        leadScientist: 5,
        studyMonitor: 6,
        studyDirector: 7,
        description: 8,
        line: 9,
        species: 10,
        testArticles: 11,
        groups: 12,
        orderID: 13,
        institutions: 14,
        permit: 15,
        permitOwner: 16,
        iacuc: 17,
        compliance: 18,
        status: 19,
        jobType: 20,
        projectedStartDate: 21,
        startDate: 22,
        durationDays: 23,
        endDate: 24,
        location: 25,
        standardPhrases: 26,
        characteristics: 27,
        costDuration: 28,
        created: 29,
        files: 30,
        reports: 31,
    };

    jobTableOptions: JobTableOptions;

    private subscription = new Subscription();

    readonly COMPONENT_LOG_TAG = 'job-pharma-facet';

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    activeFields: string[] = [];
    requiredFields: string[] = [];
    samplesActiveFields: string[] = [];
    samplesRequiredFields: string[] = [];

    constructor(
        protected baseFacetService: BaseFacetService,
        protected cellFormatterService: CellFormatterService,
        protected jobLogicService: JobLogicService,
        protected jobService: JobService,
        protected translationService: TranslationService,
        protected modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private featureFlagService: FeatureFlagService,
        private dataContext: DataContextService,
        private jobPharmaCoreService: JobPharmaCoreService,
        private settingService: SettingService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.isCRO = this.jobService.getIsCroFlag();
        this.isGLP = this.jobService.getIsGLPFlag();
        this.isCRL = this.jobService.getIsCrlFlag();

        // Set details order
        this.jobDetailsOrder = this.isCRO ? this.JOB_DETAILS_CRO_ORDER : this.JOB_DETAILS_NORMAL_ORDER;

        if (this.isCRL) {
            Object.entries(this.jobDetailsOrder).forEach(([key, value]: [string, number]) => {
                if (value > this.jobDetailsOrder['standardPhrases']) {
                    this.jobDetailsOrder[key] = value + 1;
                }
            });
            this.jobDetailsOrder['variablePhrases'] = this.jobDetailsOrder['standardPhrases'] + 1;
        } else {
            Object.entries(this.jobDetailsOrder).forEach(([key, value]: [string, number]) => {
                if (value >= this.jobDetailsOrder['projectedStartDate']) {
                    this.jobDetailsOrder[key] = value + 1;
                }
            });
            this.jobDetailsOrder['variablePhrases'] = this.jobDetailsOrder['projectedStartDate'] - 1;
        }

        this.jobTableOptions = new JobTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.featureFlagService,
            this.jobDetailsOrder            
        );

        const columns = this.isCRO ? this.jobTableOptions.croOptions.columns : this.jobTableOptions.options.columns;
        this.dataTableColumns = new BehaviorSubject(columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadJobsList(tableState);
            }
        };

        if (this.jobService && this.jobService.changeStudies2ViewMethodCalled$) {
            this.subscription.add(
                this.jobService.changeStudies2ViewMethodCalled$.subscribe(() => {
                    this.changeView(this.LIST_VIEW);
                })
            );
        }

    }

    // lifecycle
    async ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['animal-filter'];

        this.initialize();

        this.createPaginator();

        this.subscription.add(
            this.dataContext.onCancel$.subscribe(() => {
            this.changeView(this.LIST_VIEW);
        }));


        this.subscription.add(
            this.dataContext.onRejectEntityChange$.subscribe((entity: Entity<unknown>) => {
                if (entity.entityType.shortName !== 'JobMaterial') {
                    return;
                }

                const jobMaterial = entity as unknown as JobMaterial;
                if (jobMaterial.Material.Sample) {
                    this.jobPharmaCoreService.rejectSampleJobMaterialChange(jobMaterial);
                }
                if (jobMaterial.Material.Animal) {
                    this.jobPharmaCoreService.rejectAnimalJobMaterialChange(jobMaterial);
                }
            }
        ));

        await this.initializeFacetSettings();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    restoreFilterState() {
        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                // convert facet-specific data
                this.filter.DateCreatedStart = filterToDate(this.filter.DateCreatedStart);
                this.filter.DateCreatedEnd = filterToDate(this.filter.DateCreatedEnd);

                this.filter.ProjectedStartDateStart = filterToDate(this.filter.ProjectedStartDateStart);
                this.filter.ProjectedStartDateEnd = filterToDate(this.filter.ProjectedStartDateEnd);

                this.filter.DateStartedStart = filterToDate(this.filter.DateStartedStart);
                this.filter.DateStartedEnd = filterToDate(this.filter.DateStartedEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadJobsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.jobService.getJobsWithMinimumDependencies({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            });

            const visibleColumns = this.getVisibleColumns(this.jobTableOptions.options);
            await this.jobService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();
            this.data = response.results as Entity<Job>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.loading = true;
        this.jobLogicService.createNewJob().then((job) => {
            this.loading = false;
            this.itemToEdit = job;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    modelCopied(itemCopied: Entity<Job>) {
        this.itemToEdit = itemCopied;

        const showToast = true;
        this.loggingService.logSuccess(
            this.translationService.translate('Job') + ' copied',
            null,
            this.COMPONENT_LOG_TAG,
            showToast
        );
        this.changeView(this.DETAIL_VIEW);
    }

    openFilter() {
        const ref = this.modalService.open(JobFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as JobFilterComponent;
        component.filter = this.filter;
        component.isCRO = this.isCRO;
        component.isGLP = this.isGLP;
        this.subscription.add(component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        }));
    }

    selectedRowsChange(rows: any[]) {
        if (this.workspaceFilterActive) {
            this.filterWorkspaceByJob();
        }
    }

    filterWorkspaceByJob() {
        let jobKeys = this.selectedRows.map((job) => job.C_Job_key);
        const maxJobs = 10;

        if (jobKeys.length > maxJobs) {
            // Limit number of IDs to avoid errors b/c of GET URL length.
            // TODO Use POST for filtering facets
            //   http://breeze.github.io/doc-breeze-labs/ajax-post.html
            jobKeys = jobKeys.slice(0, maxJobs);

            const showToast = true;
            this.loggingService.logWarning(
                'Limiting workspace filter to ' + maxJobs + ' ' +
                this.translationService.translate('jobs'),
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );
        }

        this.workspaceFilterService.filterWorkspace(this.facetId, 'job-filter', jobKeys);
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    exitDetail() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.jobService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    private async initializeFacetSettings() {
        this.activeFields = [];
        this.requiredFields = [];
        this.samplesActiveFields = [];
        this.samplesRequiredFields = [];

        const promises = [];
        promises.push(this.initializeMainFacetSettings());
        promises.push(this.initializeSampleFacetSettings());
        if (this.isGLP || this.isCRO) {
            promises.push(this.initializeStudyDirectorFacetSetting());
        }
        
        await Promise.all(promises);
    }

    private async initializeMainFacetSettings() {
        const facetSettingsForJob = await this.settingService.getFacetSettingsByType('job', this.isCRO, this.isGLP, this.isCRL);
        this.activeFields = [...this.activeFields, ...this.settingService.getActiveFields(facetSettingsForJob)];
        this.requiredFields = [...this.requiredFields, ...this.settingService.getRequiredFields(facetSettingsForJob)];
    }

    private async initializeStudyDirectorFacetSetting() {
        const results = await this.settingService.getFacetSettingsByTypeAndField('job', 'StudyDirector');
        const facetSetting = results[0];
        if (facetSetting.IsActive) {
            this.activeFields = [...this.activeFields, 'Job Director'];
        }
        if (facetSetting.IsRequired) {
            this.requiredFields = [...this.requiredFields, 'StudyDirector'];
        }
    }

    private async initializeSampleFacetSettings() {
        const facetSettingsForSamples = await this.settingService.getFacetSettingsByType('sample');
        this.samplesActiveFields = this.settingService.getActiveFieldValues(facetSettingsForSamples);
        this.samplesRequiredFields = this.settingService.getRequiredFields(facetSettingsForSamples);
    }
}
