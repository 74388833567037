import { Permit } from '@common/types/models/permit.interface';
import { CellFormatterService, TableColumnDef, TableOptions } from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions, addColumnsToOptions, pipe } from '@common/datatable/utils';

export class PermitTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'permits.csv',
            enableDeleteColumn: false,
            enableDetailColumn: true,
            enableDraggable: true,
            dragOnlyActiveRows: true,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'Owner',
                    field: 'Resource.ResourceName',
                    position: 1,
                },
                {
                    displayName: 'Number',
                    field: 'PermitNumber',
                    position: 2,
                },
                {
                    displayName: 'Title',
                    field: 'Title',
                    visible: false,
                    position: 3,
                },
                {
                    displayName: 'Status',
                    field: 'cv_PermitStatus.PermitStatus',
                    position: 4,
                },
                {
                    displayName: 'Category',
                    field: 'cv_PermitCategory.PermitCategory',
                    position: 5,
                },
                {
                    displayName: 'GMO Animals Allowed',
                    field: 'GMOAnimalsAllowed',
                    visible: false,
                    position: 6,
                    formatter: (row: Permit, value: boolean) => value ? 'Yes' : 'No',
                },
                {
                    displayName: 'Start Date',
                    field: 'StartDate',
                    visible: false,
                    position: 7,
                    formatter: this.cellFormatterService.dateFormatter,
                },
                {
                    displayName: 'Expiry Date',
                    field: 'ExpiryDate',
                    visible: false,
                    position: 8,
                    formatter: this.cellFormatterService.dateFormatter,
                },
                {
                    displayName: 'External Purpose',
                    field: 'cv_PermitExternalPurpose.PermitExternalPurpose',
                    visible: false,
                    position: 9,
                },
                {
                    displayName: 'Internal Purpose',
                    field: 'OtherPurpose',
                    visible: false,
                    position: 10,
                },
                {
                    displayName: `${this.translationService.translate('Institution')}s`,
                    field: 'InstitutionsSortable',
                    visible: false,
                    position: 11,
                },
                {
                    displayName: 'Species',
                    field: 'SpeciesSortable',
                    visible: false,
                    position: 12                  
                },
                {
                    displayName: `${this.translationService.translate('Line')}s`,
                    field: 'LinesSortable',
                    visible: false,
                    position: 13,
                },
                {
                    displayName: 'Animals On Permit',
                    field: 'AnimalsAllowedOnPermit',
                    visible: false,
                    position: 14,
                },
                {
                    displayName: 'Highest Severity Allowed',
                    field: 'HighestSeveritiesSortable',
                    visible: false,
                    position: 15,
                },
                {
                    displayName: 'Non-Recovery Allowed',
                    field: 'NoRecoveryAllowedSortable',
                    visible: false                    
                },
                {
                    displayName: 'Origin(s)',
                    field: 'OriginsSortable',
                    visible: false,
                    position: 17,
                },
                {
                    displayName: 'End State Animals',
                    field: 'EndStateAnimals',
                    visible: false,
                    position: 18,
                },
                {
                    displayName: 'Live Animals',
                    field: 'LiveAnimals',
                    visible: false,
                    position: 19,
                },
                {
                    displayName: 'Approx. Animals Assigned',
                    field: 'TotalAnimals',
                    visible: false,
                    position: 20,
                },
                {
                    displayName: 'Approx. Animals Remaining',
                    field: 'AnimalsRemaining',
                    visible: false,
                    position: 21,
                },
                {
                    displayName: 'Reuse Allowed',
                    field: 'ReuseAllowedSortable',
                    visible: false                    
                },
                {
                    displayName: 'Summary',
                    field: 'Summary',
                    visible: false,
                    sortable: false,
                    position: 28,
                }
            ]
        };
    }
}
