<div class="facet" id="roles-panel">
    <facet-header
        caption="Roles"
        [facet]="facet">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" #rolesPanelBody style="overflow:hidden !important;" *ngIf="facet.Privilege !== 'None'">
        <div class="alert alert-danger" role="alert" *ngIf="dangerMessage">{{dangerMessage}}</div>

        <climb-facet-toolbar>
            <button *ngIf="facetEditable"
                    climbAddButton
                    (click)="addRole()">
                Add Role
            </button>

            <button *ngIf="facet.Privilege === 'ReadWrite'"
                    climbSaveButton
                    [disabled]="detailToolbar.isSaveDisabled"
                    (click)="onSaveRole()">
            </button>
        </climb-facet-toolbar>

        <div class="detail-header d-none">
            <detail-toolbar #detailToolbar
                            [showListViewButton] ="false"
                            [facetPrivilege]="facet.Privilege"
                            [logTag]="COMPONENT_LOG_TAG"
                            [addRole]="true"
                            (saveRole)="onSaveRole()">
            </detail-toolbar>
        </div>
        <div style="padding:0 10px 10px 10px;overflow-y:scroll;overflow-x:hidden !important;
                    height: calc(100% - 40px) !important;">
            <div style="padding-top:-20px">
                <hr class="mt-0" />
                <div class="row">
                    <div class="col-md-4 col-lg-3">
                        <ul class="facet-contentlist">
                            <li *ngFor="let role of roles"
                                class="facet-contentlistitem"
                                [ngClass]="{'facet-contentlistitem-selected': role.isSelected}">
                                <span *ngIf="role.isSelected">{{ role.RoleName || '[Unnamed]' }}</span>
                                <a href="" *ngIf="!role.isSelected" (click)="selectRole(role); false">{{ role.RoleName || '[Unnamed]' }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-md-8 col-lg-9">
                        <form novalidate *ngIf="selectedRole" class="form-label-right">
                            <div class="form-group row">
                                <label for="inputRole" class="col-md-3 col-form-label">Role</label>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="selectedRole.RoleName"
                                           [disabled]="!facetEditable"
                                           class="form-control"
                                           name="inputRole"
                                           id="inputRole">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="registrationCode" class="col-md-3 col-form-label">Registration Code</label>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="selectedRole.RegistrationCode"
                                           readonly
                                           class="form-control"
                                           name="registrationCode"
                                           id="registrationCode">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Access</label>
                                <div class="col-md-9">
                                    <div class="form-control-plaintext">
                                        <p class="text-muted" *ngIf="facetEditable">
                                            Drag to reorder.
                                        </p>
                                        <table class="table" [ngClass]="{'table-hover': facetEditable}">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="facetEditable"></th>
                                                    <th>Facet</th>
                                                    <th>Read</th>
                                                    <th>Write</th>
                                                </tr>
                                            </thead>
                                            <tbody [dragula]='dragulaBagName'
                                                   [dragulaModel]="selectedRole.ClimbRoleFacets">
                                                <tr *ngFor="let roleFacet of sortedClimbRoleFacets"
                                                    [ngClass]="{'draggable': facetEditable}">
                                                    <td class="icon-cell" *ngIf="facetEditable">
                                                        <i class="fa fa-sort text-muted" title="Drag to reorder"></i>
                                                    </td>
                                                    <td>{{ roleFacet.Facet.FacetDisplayName | translate }}</td>
                                                    <td>
                                                        <input class="pass-thru-pointer-events"
                                                               type="checkbox"
                                                               [(ngModel)]="roleFacet.HasReadAccess"
                                                               [disabled]="roleFacet.HasWriteAccess || !facetEditable"
                                                               [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td>
                                                        <input type="checkbox"
                                                               [(ngModel)]="roleFacet.HasWriteAccess"
                                                               [disabled]="!facetEditable"
                                                               (ngModelChange)="writeAccessChange(roleFacet, $event)"
                                                               [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
