export const OBJECT_TYPE_MAPPING: any = {
    allele: 1,
    animal: 2,
    animalsample: 3,
    animalshipment: 4,
    barcode: 5,
    birth: 6,
    birthsample: 7,
    client: 8,
    configurationsetting: 9,
    contactperson: 10,
    contactpersondepartment: 11,
    container: 12,
    controlledvocabulary: 13,
    cv_alleleclass: 14,
    cv_animalstatus: 15,
    cv_birthstatus: 16,
    cv_breedingstatus: 17,
    cv_containertype: 19,
    cv_country: 20,
    cv_damchangereason: 21,
    cv_datatype: 22,
    cv_diet: 23,
    cv_exitreason: 24,
    cv_generation: 25,
    cv_genotypeassay: 26,
    cv_genotypestatus: 27,
    cv_genotypesymbol: 28,
    cv_inputoutputunit: 29,
    cv_jobstatus: 30,
    cv_jobtype: 31,
    cv_linestatus: 32,
    cv_linetype: 33,
    cv_markerrepository: 34,
    cv_markertype: 35,
    cv_materialorigin: 36,
    cv_materialpooltype: 37,
    cv_materialtype: 38,
    cv_matingpurpose: 39,
    cv_matingretirementreason: 40,
    cv_matingstatus: 41,
    cv_matingtype: 42,
    cv_messageprotocol: 43,
    cv_namingconvention: 44,
    cv_resourcetype: 45,
    cv_reviewaction: 46,
    cv_samplestatus: 47,
    cv_sampletype: 48,
    cv_schedulereason: 49,
    cv_sex: 50,
    cv_state: 51,
    cv_taskoutcome: 52,
    cv_taskstatus: 53,
    cv_taxon: 54,
    cv_timerelation: 55,
    cv_timeunit: 56,
    cv_unit: 57,
    cv_volumeunit: 58,
    cv_weandirective: 59,
    deletions: 60,
    department: 61,
    enumerationclass: 62,
    enumerationitem: 63,
    genotype: 64,
    genotypeallele: 65,
    identityrole: 66,
    input: 67,
    inputdefault: 68,
    inputinstance: 69,
    institution: 70,
    job: 71,
    jobmaterial: 72,
    taskinstance: 73,
    taskinput: 74,
    taskmaterial: 75,
    taskoutput: 76,
    taskoutputset: 77,
    taskoutputsetmaterial: 78,
    taskjob: 80,
    limitdefinition: 81,
    line: 82,
    linealias: 83,
    lineallele: 84,
    linemarker: 85,
    locationposition: 86,
    login: 87,
    marker: 88,
    material: 89,
    materiallocation: 90,
    materialpool: 91,
    materialpoolmaterial: 92,
    materialtypecontainertype: 93,
    mating: 94,
    message: 95,
    messaginglog: 96,
    monitoringlog: 97,
    monitoringservice: 98,
    monitoringserviceparameter: 99,
    monitoringservicesubscription: 100,
    monitoringservicesubscriptionparameter: 101,
    monitoringservicesubscriptionprotocol: 102,
    nameformat: 103,
    namecomponentdetail: 104,
    namecomponentinstance: 105,
    namefamily: 106,
    numericlimit: 107,
    // objectmap: 108, deprecated in #24024
    objecttype: 109,
    output: 110,
    outputdefault: 111,
    outputinstance: 112,
    plugdate: 113,
    primer: 114,
    protocol: 115,
    protocoltask: 116,
    refreshtokens: 117,
    resource: 118,
    resourcegroupmember: 119,
    role: 120,
    sample: 121,
    samplecharacteristic: 122,
    samplecharacteristicinstance: 123,
    sampleorder: 124,
    schedulenontask: 125,
    storedfile: 126,
    study: 127,
    studymaterial: 128,
    taxoncharacteristic: 129,
    taxoncharacteristicinstance: 130,
    translation: 131,
    translationterm: 132,
    user: 133,
    userclaim: 134,
    usercontact: 135,
    useridentityrole: 136,
    vocabularysources: 137,
    workflowtask: 138,
    workgroup: 139,
    workgroupuser: 140,
    workgroupvocabularysource: 141,
    workspace: 142,
    workspacedetail: 143,
    taskanimalhealthrecord: 144,
    event: 145,
    animalclinicalobservation: 146,
    studyline: 147,
    clinicalobservationdetail: 148,
    materialsourcematerial: 149,
    sampleconstruct: 150,
    birthmaterial: 151,
    cohortmaterial: 152,
    jobcohort: 153,
    taskcohort: 154,
    taskcohortinput: 155,
    samplegroup: 156,
    lot: 157,
    jobtestarticle: 158,
    jobinstitution: 159,
    jobmaterialpool: 161,
    census: 162,
    censusmaterialpool: 163,
    censusmaterialpoolmaterial: 164,
    cv_censusstatus: 165,
    cv_censusdiscrepancy: 166,
    animalhealthrecord: 167,
    cv_standardphrase: 168,
    jobstandardphrase: 169,
    jobline: 170,
    taskgroupset: 171,
    jobcharacteristicinstance: 172,
    jobgrouptreatment: 173,
    animalplaceholder: 174,
    placeholder: 175,
    cohort: 176,
    cohortanimal: 177,
    order: 178,
    jobgroup: 179,
    cv_taskflagmessage: 180,
    cv_animaluse: 181,
    cv_jobsubtype: 182,
    cv_jobreport: 183,
    cv_clinicalobservationstatus: 184,
    cv_clinicalobservation: 185,
    cv_compliance: 186,
    cv_contactpersontype: 187,
    cv_bodyconditionscore: 188,
    cv_courier: 189,
    animalorder: 190,
    jobcharacteristic: 191,
    studycharacteristic: 192,
    orderlocation: 193,
    cv_testarticle: 194,
    cv_sitetype: 196,
    cv_materialpoolstatus: 197,
    cv_iacucprotocol: 198,
    cv_institutiontype: 202,
    cv_physicalmarkertype: 203,
    cv_locationtype: 206,
    cv_paymentterm: 208,
    cv_platestatus: 209,
    cv_resourcegroup: 212,
    cv_sampleanalysismethod: 213,
    cv_samplecondition: 214,
    cv_shipmentanimalcondition: 215,
    cv_animalcommentstatus: 216,
    animalcomment: 217,
    setting: 219,
    joborder: 220,
    facetsetting: 221,
    taskmaterialpool: 222,
    cv_socialgroupaccesslevel: 223,
    cv_compatibilityaccesslevel: 224,
    compatibilitymaterial: 225,
    socialgroupmaterial: 226,
    animaldiagnosticobservation: 227,
    cv_clinicalobservationbodysystem: 228,
    cv_modifier1: 229,
    cv_modifier2: 230,
    cv_modifier3: 231,
    cv_modifier4: 232,
    cv_quarantinefacility: 233,
    outputflag: 234,
    studyadministratorstudy: 235,
    cv_animalclassification: 236,
    joblocation: 237,
    cv_routeofadministration: 238,
    cv_veterinaryrequeststatus: 239,
    cv_shipmentcontainercondition: 240,
    studycharacteristicinstance: 241,
    jobvariablephrase: 242,
    cv_permitexternalpurpose: 243,
    cv_severity: 244,
    cv_permitcategory: 245,
    cv_permitstatus: 246,
    permit: 250,
    permitspecies: 251,
    permitspeciesline: 252,
    permitspeciesorigin: 253,
    permitinstitution: 254,
    orderinstitution: 255,
    cv_orderstatus: 256,
};
