import { CellFormatterService, TableOptions } from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditPermitSpeciesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'permit-species-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Species', 'Species'),
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Permit Number',
                    field: 'PermitNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitNumber', 'PermitNumber'),
                    cellClass: AuditClassFactory.createCellClass('permitnumber'),
                },
                {
                    displayName: 'Highest Severity Allowed',
                    field: 'HighestSeverityAllowed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HighestSeverityAllowed', 'HighestSeverityAllowed'),
                    cellClass: AuditClassFactory.createCellClass('highestseverityallowed'),
                },
                {
                    displayName: 'Animals On Permit',
                    field: 'AnimalsOnPermit',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalsOnPermit', 'AnimalsOnPermit'),
                    cellClass: AuditClassFactory.createCellClass('animalsonpermit'),
                },
                {
                    displayName: 'Non-Recovery Allowed',
                    field: 'NoRecoveryAllowed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('NoRecoveryAllowed', 'NoRecoveryAllowed'),
                    cellClass: AuditClassFactory.createCellClass('norecoveryallowed'),
                },
                {
                    displayName: 'Reuse Allowed',
                    field: 'ReuseAllowed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ReuseAllowed', 'ReuseAllowed'),
                    cellClass: AuditClassFactory.createCellClass('reuseallowed'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
            ]
        };
    }
}
