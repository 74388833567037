import { map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';

import { PrivilegeService } from '@services/privilege.service';

import { NamingService } from '@services/naming.service';
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';

import { empty, testBreezeIsNew } from '@common/util';
import { TranslationService } from '@services/translation.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import {
    cv_Diet,
    cv_MaterialOrigin,
    cv_MaterialType,
    cv_OrderStatus,
    cv_QuarantineFacility,
    cv_Sex,
    cv_Taxon,
    Entity,
    Institution,
    Job,
    LineTypeahead,
    Order,
    OrderLocation,
    Resource,
    SampleOrder,
} from '@common/types';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';
import { Injectable } from '@angular/core';
import { IFacet } from '@common/facet';
import { JobOrderService } from 'src/app/jobs/job-order.service';
import { LocationService } from 'src/app/locations/location.service';
import { SettingService } from 'src/app/settings/setting.service';
import { VocabularyService } from 'src/app/vocabularies/vocabulary.service';
import { ViewOrderAuditReportComponentService } from '../../audit';
import { OrderVocabService } from '../../order-vocab.service';
import { OrderService } from '../../order.service';
import { UserService } from 'src/app/user/user.service';
import { OrderInstitution } from '../../../common/types/models/order-institution.interface';
import { PermitService } from 'src/app/permits/services/permit.service';
import { ORDER_ANIMALS_ONLY_REQUIRED_FIELDS_SET, OrderActiveField, OrderRequiredField } from '../order-setting-fields';
import { Subject } from 'rxjs';

@Injectable()
export class OrderDetailService {
    facetPrivilege: string;
    order: Entity<Order> | null;

    subOrder: Entity<SampleOrder> = null;

    // CVs
    materialTypes: Entity<cv_MaterialType>[] = [];
    taxonKeyToDataMap: Map<number, Entity<cv_Taxon>> = new Map();

    materialOrigins: Entity<cv_MaterialOrigin>[] = [];
    materialOriginDefaultKey: number | null;

    quarantineFacilities: cv_QuarantineFacility[] = [];
    quarantineFacilityDefaultKey: number | null;

    resources: Entity<Resource>[] = [];

    orderStatuses: Entity<cv_OrderStatus>[] = [];
    orderStatusDefaultKey: number;

    sexes: Entity<cv_Sex>[] = [];
    sexDefaultKey: number;

    diets: Entity<cv_Diet>[] = [];
    dietDefaultKey: number | null;

    institutions: Entity<Institution>[] = [];

    selectedTaxon = '';

    // State
    saving = false;

    readonly COMPONENT_LOG_TAG = 'order-detail';

    isOrderAdministrator = false;
    lockTitle = "Only Order Administrators can lock or unlock orders";

    readonly: boolean;

    tableAnimalCount: number;
    orderNamingActive = false;
    jobOrderKey: number | null;

    // Active and required fields set by facet settings
    activeFields: Set<OrderActiveField> = new Set();

    animalsAndSampleRequiredFields: OrderRequiredField[] = [];
    requiredFields: Set<OrderRequiredField> = new Set();

    animalOrderActiveFields: Set<string> = new Set();
    animalOrderRequiredFields: Set<string> = new Set();

    animalReceiptCheckActiveFields: Set<string> = new Set();

    private activeFieldsChanged = new Subject<void>();
    activeFieldsChanged$ = this.activeFieldsChanged.asObservable();

    isGLP: boolean;

    loading: boolean;
    materialTypeLoading: boolean;

    permitOwner = '';

    isShowAnimalForm = true

    constructor(
        private privilegeService: PrivilegeService,
        private orderService: OrderService,

        private jobOrderService: JobOrderService,
        private orderVocabService: OrderVocabService,
        private viewOrderAuditReportComponentService: ViewOrderAuditReportComponentService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private namingService: NamingService,
        private reasonForChangeService: ReasonForChangeService,
        private settingService: SettingService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
        private loggingService: LoggingService,
        private userService: UserService,
        private permitService: PermitService
    ) {

    }

    async generateId(): Promise<void> {
        if (this.order.OrderID == null) {
            this.order.OrderID = await this.orderService.autoGenerateOrderID(this.order);
        }
    }

    initIsGLP(): void {
        const flag = this.featureFlagService.getFlag('IsGLP');
        this.isGLP = flag && flag.IsActive && flag.Value.toLowerCase() === 'true';
    }

    async initializeMaterialType(): Promise<void> {
        await this.orderVocabService.materialTypes$
            .pipe(
                map((data) => {
                    this.materialTypes = data;

                    if (testBreezeIsNew(this.order)) {
                        const defaultOrderType = this.materialTypes.find((item) => {
                            return item.IsDefault;
                        });
                        this.order.cv_MaterialType = defaultOrderType;
                        this.orderTypeChanged();
                    } else if (this.order.cv_MaterialType?.MaterialType !== 'Animal') {
                        this.isShowAnimalForm = false;
                    }
                }),
            )
            .toPromise();
    }

    async initialize(order: Entity<Order>, facet?: IFacet): Promise<void> {
        this.order = order
        this.onPermitSelect(order.C_Permit_key);

        if (facet != null) {
            this.facetPrivilege = facet.Privilege;
        }

        this.materialTypeLoading = true;
        this.loading = true;

        this.initializeMaterialType();

        this.initIsGLP();

        this.setPrivileges();

        this.getOrderAdminStatus();

        try {
            await this.getActiveFields();

            this.materialTypeLoading = false;

            this.getInstitutions();
            this.getSubOrder();
            this.isNamingActive();
            this.getJobOrderKey();

            await this.getCVs();

            if (this.isGLP) {
                // force to have OrderType = animal
                const animalOrderType = this.materialTypes.find((item) => {
                    return item.MaterialType.toLowerCase() === 'animal';
                });
                this.order.cv_MaterialType = animalOrderType;
                this.orderTypeChanged();
            }

            this.setDefaultCVValues();

            await this.getDetails();
        } finally {
            this.materialTypeLoading = false;
            this.loading = false;
        }
    }

    private async getActiveFields(): Promise<void> {
        const facetSettings = await this.settingService.getFacetSettingsByType('order');
        this.activeFields = new Set(this.settingService.getActiveFields(facetSettings) as OrderActiveField[]);
        this.animalsAndSampleRequiredFields = this.settingService.getRequiredFields(facetSettings) as OrderRequiredField[]

        this.updateRequiredFields()

        if (this.isShowAnimalForm) {
            const animalTypeFacetSettings = await this.settingService.getFacetSettingsByType(
                'order-animal-receipt-check',
            );
            this.animalReceiptCheckActiveFields = new Set(
                this.settingService.getActiveFields(animalTypeFacetSettings),
            );

            const animalOrderFacetSettings = await this.settingService.getFacetSettingsByType('order-animal');
            this.animalOrderActiveFields = new Set(this.settingService.getActiveFields(animalOrderFacetSettings));
            this.animalOrderRequiredFields = new Set(this.settingService.getRequiredFields(animalOrderFacetSettings));
        }

        this.activeFieldsChanged.next()
    }

    private updateRequiredFields() {
        if (this.isShowAnimalForm) {
            this.requiredFields = new Set(this.animalsAndSampleRequiredFields)
        } else {
            this.requiredFields = new Set(
                this.animalsAndSampleRequiredFields
                    .filter(field => !ORDER_ANIMALS_ONLY_REQUIRED_FIELDS_SET.has(field))
            )
        }
    }

    private setDefaultCVValues() {
        this.orderStatusDefaultKey = this.orderStatuses.find((item) => item.IsDefault)?.C_OrderStatus_key;
        this.sexDefaultKey = this.sexes.find((item) => item.IsDefault)?.C_Sex_key;
        this.dietDefaultKey = this.diets.find((item) => item.IsDefault)?.C_Diet_key;
        this.materialOriginDefaultKey = this.materialOrigins.find((item) => item.IsDefault)?.C_MaterialOrigin_key;
        this.quarantineFacilityDefaultKey = this.quarantineFacilities.find(
            (item) => item.IsDefault,
        )?.C_QuarantineFacility_key;
    }

    private async isNamingActive(): Promise<void> {
        const active = await this.namingService.isOrderNamingActive();
        this.orderNamingActive = active;
    }

    private async getCVs(): Promise<void> {
        const p1 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxonKeyToDataMap = new Map(data.map((taxon) => [taxon.C_Taxon_key, taxon]));

            if (this.order) {
                this.setSelectedTaxon(this.order.C_Taxon_key)
            }
        })).toPromise();

        const p2 = this.orderVocabService.materialOrigins$.pipe(map((data) => {
            this.materialOrigins = data;
        })).toPromise();

        const p3 = this.orderVocabService.quarantineFacilities$.pipe(map((data) => {
            this.quarantineFacilities = data;
        })).toPromise();

        const p4 = this.orderVocabService.orderStatuses$.pipe(map((data) => {
            this.orderStatuses = data;
        })).toPromise();

        const p5 = this.orderVocabService.sexes$.pipe(map((data) => {
            this.sexes = data;
        })).toPromise();

        const p6 = this.orderVocabService.orderResources$.subscribe((resources) => {
            this.resources = resources;
        });

        const p7 = this.orderVocabService.diets$.pipe(map((data) => {
            this.diets = data;
        })).toPromise();

        await Promise.all([p1, p2, p3, p4, p5, p6, p7]);
    }

    private setSelectedTaxon(taxonKey: number | null): void {
        this.selectedTaxon = taxonKey != null ? this.taxonKeyToDataMap.get(taxonKey)?.CommonName || '' : ''
    }

    private getDetails(): Promise<Entity<Order>> {
        if (this.order && this.order.C_Order_key > 0) {
            return this.orderService.getOrder(this.order.C_Order_key);
        }

        return Promise.resolve(this.order);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
    }

    onCancel(): void {
        this.orderService.cancelOrder(this.order);
    }

    viewAuditReport(): void {
        this.viewOrderAuditReportComponentService.openComponent(this.order.C_Order_key);
    }

    // Formatters for <select> input
    quarantineFacilityKeyFormatter = (value: cv_QuarantineFacility): number => {
        return value.C_QuarantineFacility_key;
    };
    quarantineFacilityFormatter = (value: cv_QuarantineFacility): string => {
        return value.QuarantineFacility;
    };

    async getInstitutions(): Promise<void> {
        const institutions = await this.orderService.getInstitutions();
        this.institutions = institutions;
    }

    onLineSelection(line: LineTypeahead | null): void {
        this.order.C_Taxon_key = line?.TaxonKey || null;
        this.setSelectedTaxon(line?.TaxonKey);
    }

    orderTypeChanged(): void {
        this.isShowAnimalForm = this.order.cv_MaterialType?.MaterialType === 'Animal';
        this.updateRequiredFields()

        // Check for current order type
        if (this.order.cv_MaterialType && this.order.cv_MaterialType?.MaterialType === 'Sample') {
            // Create new sample order
            this.subOrder = this.orderService.createSampleOrder(this.order.C_Order_key);
            this.vocabularyService.getCVDefault('cv_SampleTypes').then((value) => {
                this.subOrder.cv_SampleType = value;
            });
            this.vocabularyService.getCVDefault('cv_SampleConditions').then((value) => {
                this.subOrder.cv_SampleCondition = value;
            });
        } else {
            // Delete the current sample order
            if (this.subOrder) {
                this.reasonForChangeService.markModification([this.subOrder.Order]);
                this.orderService.deleteSampleOrder(this.subOrder);
            }
        }
    }

    getSubOrder(): void {
        // Get Sample order details if necessary
        if (this.order.cv_MaterialType && this.order.cv_MaterialType?.MaterialType === 'Sample') {
            this.orderService.getSampleOrder(this.order.C_Order_key).then((data) => {
                this.subOrder = data;
            });
        }
    }

    addOrderLocation(): void {
        this.locationService.getDefaultLocation().then((defaultLocation) => {
            const dateIn = new Date();
            this.orderService.createOrderLocation({
                C_LocationPosition_key: defaultLocation.C_LocationPosition_key,
                C_Order_key: this.order.C_Order_key,
                DateIn: dateIn,
            });
        });
    }

    removeOrderLocation(orderLocation: Entity<OrderLocation>): void {
        this.reasonForChangeService.markModification([orderLocation.Order]);
        this.orderService.deleteOrderLocation(orderLocation);
    }

    getJobOrderKey(): void {
        this.jobOrderKey = this.order.JobOrder.length !== 0 ? this.order.JobOrder[0].C_Job_key : null;
        this.order.C_Job_key = this.jobOrderKey;
    }

    async onSelectJob(item: Job): Promise<void> {
        if (this.order.JobOrder.length !== 0) {
            await this.jobOrderService.deleteJobOrder(this.order.JobOrder[0]);
        }
        if (item) {
            const initialValues = {
                C_Job_key: item,
                C_Order_key: this.order.C_Order_key,
            };
            await this.jobOrderService.createJobOrder(initialValues);
        }
    }

    async validate(dateControls: NgModel[]): Promise<string> {
        const translatedOrder = this.translationService.translate('Order');

        const dateErrorMessage = dateControlValidator(dateControls);
        if (dateErrorMessage) {
            return dateErrorMessage;
        }

        // Check that auto-naming field has value
        if (this.orderNamingActive && testBreezeIsNew(this.order)) {
            const invalidField = await this.orderService.validateOrderNamingField(this.order);
            if (invalidField) {
                return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
            }
        } else if (empty(this.order.OrderID)) {
            return `A ${translatedOrder} requires an ID.`;
        }

        // Also validate fields required by facet settings for each animal order
        for (const animalOrder of this.order.AnimalOrder) {
            const errorMessage = await this.settingService.validateRequiredFields(
                [...this.animalOrderRequiredFields],
                animalOrder,
                'order-animal',
            );
            if (errorMessage) {
                return errorMessage;
            }
        }

        const areOrderInstitutionValid = (this.order.OrderInstitution ?? []).every((item: OrderInstitution) => item.C_Institution_key);

        if (!areOrderInstitutionValid) {
            return `Ensure that all required fields within ${this.translationService.translate('Institutions')} are filled.`;
        }

        const areLocationsValid = (this.order.OrderLocation ?? []).every(
            (location: OrderLocation) => location.C_LocationPosition_key,
        );

        if (!areLocationsValid) {
            return 'Ensure that all required fields within Locations are filled.';
        }

        return await this.settingService.validateRequiredFields([...this.requiredFields], this.order, 'order');
    }

    async onSaveResult(): Promise<void> {
        this.loggingService.logDebug(
            'Save result reported in Order Details (may have succeeded or failed)',
            null,
            this.COMPONENT_LOG_TAG,
        );

        // If new entity creation wasn't failed
        if (!testBreezeIsNew(this.order)) {
            await this.initialize(this.order);
        }

        this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
    }

    updateOrderID(field: string): void {
        // Apply new number only if is an update
        this.isNamingActive().then(() => {
            if (this.isGLP && this.order.OrderID && this.orderNamingActive) {
                this.orderService.getOrderPrefixField().then((orderPrefixField: string) => {
                    if (orderPrefixField.toLowerCase() === field.toLowerCase()) {
                        // Automatically regenerate JobID
                        this.orderService.autoGenerateOrderID(this.order).then((newID: string) => {
                            if (newID !== this.order.OrderID) {
                                this.order.OrderID = newID;
                                // Alert user of automatic change
                                this.loggingService.logWarning(
                                    `The Order ID field has been automatically changed due to changing the ${orderPrefixField} field.`,
                                    null,
                                    this.COMPONENT_LOG_TAG,
                                    true,
                                );
                            }
                        });
                    }
                });
            }
        });
    }

    clearValues(): void {
        this.order = null;
        this.subOrder = null;
        this.isShowAnimalForm = true;
    }

    getOrderAdminStatus(): Promise<void> {
        return this.userService.getThisWorkgroupUser().then((workgroupUser: any) => {
            this.isOrderAdministrator = workgroupUser.OrderAdministrator;
        });
    }

    onOrderChange(): void {
        this.getOrderAdminStatus();
    }

    public async onPermitSelect(permitKey: number): Promise<void> {
        this.permitOwner = '';
        const permit = await this.permitService.getPermit(permitKey, ['Resource']);
        this.permitOwner = permit?.Resource.ResourceName || '';
    }

    get hasInstitutionsWithoutSite(): boolean {
        return this.order.OrderInstitution?.some(x => !x.C_Site_key);
    }
}
