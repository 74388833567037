import { ChangeDetectorRef } from '@angular/core';
import { ProtocolDateCalculator } from '../tasks/tables/protocol-date-calculator';
import { TaskType } from '../tasks/models';
import { TaskService } from '../tasks/task.service';
import { TableSort } from '@common/models';
import { DetailTaskTableComponent, DetailTaskTableOptions } from '../tasks/tables';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnimalService } from './services/animal.service';
import { AnimalVocabService } from './services/animal-vocab.service';
import { ClinicalService } from '../clinical/clinical.service';
import { EnumerationService } from '../enumerations/enumeration.service';
import { LineService } from '../lines/line.service';
import { NamingService } from '@services/naming.service';
import { SearchService } from '../search/search.service';
import { AnimalLogic } from './animal-logic.shared';
import { ViewAnimalAuditReportComponentService } from './audit';

import { SearchQueryDef } from '../search/search-query-def';
import { QueryDef } from '@services/query-def';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState,
} from '@common/facet';
import {
    empty,
    maxSequence,
    randomId,
    scrollToElement,
    testBreezeIsNew,
    uniqueArrayFromPropertyPath
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { OnSaveSuccessful, SaveChangesService, IValidatable } from '@services/save-changes.service';
import { Observable, Subscription, merge } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { DotmaticsService } from '../dotmatics/dotmatics.service';
import { WorkflowVocabService } from '../workflow/services/workflow-vocab.service';
import { APIQueryDef } from '@services/api-query-def';
import { TaskStatusChangedEventArgs, WorkflowService } from '../workflow/services/workflow.service';
import { WorkflowTableOptions } from '../workflow/helpers/workflow-table-options';
import { CellFormatterService } from '@common/datatable';
import { TranslationService } from '@services/translation.service';
import { FeatureFlagService, LARGE_ANIMAL } from '@services/feature-flags.service';
import { JobVocabService } from '../jobs/job-vocab.service';
import { DataType } from '../data-type';
import { intersectionWith } from '@lodash';
import { NgForm, NgModel } from '@angular/forms';
import { SettingService } from '../settings/setting.service';
import { AnimalSaveService } from './services/animal-save.service';
import type {
    Animal,
    Entity,
    cv_AnimalStatus,
    cv_AnimalUse,
    cv_BreedingStatus,
    cv_Diet,
    cv_ExitReason,
    cv_Generation,
    cv_MaterialOrigin,
    cv_IACUCProtocol,
    cv_PhysicalMarkerType,
    cv_Sex,
    cv_JobStatus,
    cv_JobType,
    cv_AnimalCommentStatus,
    WorkflowTask,
    cv_AnimalMatingStatus,
    TaskOutput,
    TaskMaterial,
    LineTypeahead
} from '@common/types';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from '../characteristics/characteristic-input/characteristic-input.component';
import { EventHistoryComponent } from '../events/event-history.component';
import { PermitService } from '../permits/services/permit.service';

type History = Record<string, any>;
type Protocol = Record<string, unknown>;

interface AnimalExtended {
    History: History[];
    Lines: LineTypeahead[];
    ClinicalObservationCount: number;
    DiagnosticObservationCount: number;
    Protocols: Protocol[];
    inputsExpanded: boolean;
    tasksExpanded: boolean;
    taskAnimalsExpanded: boolean;
    taskCohortsExpanded: boolean;
    taskSamplesExpanded: boolean;
}

interface ITaskOutput {
    C_Output_key: number;
    C_WorkflowTask_key: number;
    OutputName: string;
    xValues: Date[];
    yValues: number[];
    completed: boolean[];
}

class TaskSelectionConfig {
    outputKeys: number[];
    keys: string[];
    type: string;
}
@Component({
    selector: 'animal-detail',
    templateUrl: './animal-detail.component.html'
})
export class AnimalDetailComponent extends BaseDetail
    implements OnChanges, OnInit, OnDestroy, IValidatable, OnSaveSuccessful {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('eventHistory') eventHistories: EventHistoryComponent[];
    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() animal: Entity<Animal> & AnimalExtended;
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];
    @Input() taskData: any; // Used to sync process
    @Input() isSyncItem: boolean;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("animalForm") animalForm: NgForm;

    // expose enum to template
    TaskType = TaskType;

    animalLogic: AnimalLogic;

    // CVs
    animalStatuses: Entity<cv_AnimalStatus>[] = [];
    animalUses: Entity<cv_AnimalUse>[] = [];
    breedingStatuses: Entity<cv_BreedingStatus>[] = [];
    animalClassifications: any[] = [];
    animalMatingStatuses: Entity<cv_AnimalMatingStatus>[] = [];
    diets: Entity<cv_Diet>[] = [];
    exitReasons: Entity<cv_ExitReason>[] = [];
    generations: Entity<cv_Generation>[] = [];
    materialOrigins: Entity<cv_MaterialOrigin>[] = [];
    sexes: Entity<cv_Sex>[] = [];
    iacucProtocols: Entity<cv_IACUCProtocol>[] = [];
    physicalMarkerTypes: Entity<cv_PhysicalMarkerType>[] = [];
    animalCommentStatuses: Entity<cv_AnimalCommentStatus>[] = [];
    jobStatuses: Entity<cv_JobStatus>[] = [];
    jobTypes: Entity<cv_JobType>[] = [];

    // State
    selectedAnimals: any[];
    animalNamingActive = false;

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    taskTableSort: TableSort = new TableSort();

    readonly COMPONENT_LOG_TAG = 'animal-detail';

    subs: Subscription = new Subscription();

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    isGLP = false;
    largeAnimalEnabled = false
    hasChanges$ = this.dataContext.entityChanges$.pipe(
      map(() => this.hasChanges()),
      distinctUntilChanged(),
    );

    animalTasks: any = [];
    animalWorkflowTaskKeys: any = [];
    validationErrorsPresent = false;
    animalWorkflowTasks: Entity<WorkflowTask>[] = [];
    relatedAnimalWorkflowTasks: Entity<WorkflowTask>[] = [];
    jobTasks: any = [];
    jobWorkflowTaskKeys: any = [];
    jobWorkflowTasks: Entity<WorkflowTask>[] = [];
    relatedJobWorkflowTasks: Entity<WorkflowTask>[] = [];
    jobTasksOutputs: Entity<ITaskOutput>[] = [];
    selectedJobTaskOutputKeys: number[] = [];
    chartJobOutputs: Entity<ITaskOutput>[] = [];
    animalTasksOutputs: Entity<ITaskOutput>[] = [];
    selectedAnimalTaskOutputKeys: number[] = [];
    chartAnimalOutputs: Entity<ITaskOutput>[] = [];
    originalPermitKey: number | null;
    originalDateOnPermit: Date | null;
    typeahead: any;
    syncOutputSubscription: any;
    syncTaskStatusChangedSubscription: any;
    endStateTaskStatusKeys: number[] = [];
    taskStatusKeys: number[] = [];
    visibleColumns: string[] = [
        'TaskAlias',
        'Cohort',
        'DateDue',
        'Inputs',
        'OutputSample',
        'Outputs',
        'CompletedBy',
        'CompletedDate',
        'ReviewedBy',
        'ReviewedDate',
        'TaskStatus',
        'Notes',
        'Lock'
    ];
    studyColumns = ['JobID', ...this.visibleColumns];
    workflowFacet = { Privilege: "ReadOnly" };
    busy = 0;
    private readonly rapIdMarkerType: string = "RapID";
    private readonly physicalMarkerTypeKey: string = "PhysicalMarker";

    originalAlternatePhysicalID: string;
    originalMicrochipIdentifier: string;
    saveAnimalLoading$ = this.animalSaveService.saveEntityLoading$;
    tasksLoading: boolean;

    saveEventSubscriptions: Subscription[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        private animalService: AnimalService,
        private animalSaveService: AnimalSaveService,
        private saveChangesService: SaveChangesService,
        private animalVocabService: AnimalVocabService,
        baseDetailService: BaseDetailService,
        private clinicalService: ClinicalService,
        private enumerationService: EnumerationService,
        private lineService: LineService,
        private namingService: NamingService,
        private searchService: SearchService,
        private taskService: TaskService,
        private modalService: NgbModal,
        private viewAnimalAuditReportComponentService: ViewAnimalAuditReportComponentService,
        private dotmaticsService: DotmaticsService,
        private workflowVocabService: WorkflowVocabService,
        private workflowService: WorkflowService,
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
        private jobVocabService: JobVocabService,
        private settingService: SettingService,
        private dateFormatterService: DateFormatterService,
        private permitService: PermitService,
    ) {
        super(baseDetailService);

        this.animalLogic = new AnimalLogic(
            animalService,
            enumerationService
        );
    }

    // lifecycle
    async ngOnInit() {
        this.saveChangesService.registerValidator(this);
        const saveSuccessfullySub = merge(
          this.saveChangesService.saveSuccessful$,
          this.animalSaveService.saveSuccessful$,
        ).subscribe(() => {
          this.onSaveSuccessful();
        });

        this.subs.add(saveSuccessfullySub);
  
        await this.initialize();
        this.syncOutputSubscription = this.workflowService.syncOutputValues$.subscribe((taskOutput: TaskOutput) => {
            this.syncOutputValues(taskOutput);
            this.filterWorkflowTasks();
        });

        this.syncTaskStatusChangedSubscription = this.workflowService.syncTaskStateChange$.subscribe((args: TaskStatusChangedEventArgs) => {
            this.syncTaskStateChange(args);
            this.filterWorkflowTasks();
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.saveChangesService.unregisterValidator(this);
        if (this.syncOutputSubscription) {
            this.syncOutputSubscription.unsubscribe();
        }
        if (this.syncTaskStatusChangedSubscription) {
            this.syncTaskStatusChangedSubscription.unsubscribe();
        }
    }


    async ngOnChanges(changes: any) {
        if (!changes.animal) {
            // Make sure that a different task is selected in the Animals facet (do not reload if the same workflow task instance is selected).
            if (this.isSyncItem && this.hasTaskDataChanges(changes)) {
                await this.setupSyncItem();
            }
            return;
        }

        if (this.animal && changes.animal.firstChange) {
            return;
        }

        if (this.isSyncItem) {
            await this.handleUnsavedChangesOnExit();
            await this.getAnimalDetails();
            await this.setupSyncItem();
        } else {
            await this.getAnimalDetails();
            this.resetOutputs();
        }
        this.filterWorkflowTasks();
    }

    async initialize(): Promise<void> {
        this.setTableStates();
        this.initTableOptions();
        this.setIsDotmatics();
        this.initIsGLP();
        this.initLargeAnimalEnabled();

        this.setBusy(true);
        try {
            await this.getCVs();
            await this.isNamingActive();
            await this.getAnimalDetails();
            await this.getAnimalTasks();
            if (this.isSyncItem) {
                await this.setupSyncItem();
            } else {
                let p1 = Promise.resolve();
                let p2 = Promise.resolve();
                const selectConfig = this.parseSelectionConfig();
                if (selectConfig[0]?.keys?.length > 0) {
                    this.animalWorkflowTaskKeys = selectConfig[0].keys;
                    p1 = this.animalService.fetchOutputs(selectConfig[0].keys).then((outputs: any) => {
                        this.selectedAnimalTaskOutputKeys = outputs.filter((output: any) => selectConfig[0].keys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                        this.onAnimalSelect();
                    });
                }

                if (selectConfig[1]?.keys?.length > 0) {
                    this.jobWorkflowTaskKeys = selectConfig[1].keys;
                    p2 = this.animalService.fetchOutputs(selectConfig[1].keys).then((outputs: any) => {
                        this.selectedJobTaskOutputKeys = outputs.filter((output: any) => selectConfig[1].keys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                        this.onJobSelect();
                    });
                }
                return Promise.all([p1, p2]) as unknown as Promise<void>;
            }
        } finally {
            this.filterWorkflowTasks();
            this.setBusy(false);
        }
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }

    /*
    Ensures that taskData has actually changed.
    If Sync Workflow and Animals facet is enabled, it is used to prevent the facet from synchronizing if another task instance is selected but has the same
    workflow task instance id. In this scenario, the data for this workflow task has already been displayed in the Animals facet.
    */
    private hasTaskDataChanges(changes: any): boolean {
        if (!changes && !changes.taskData) {
            return false;
        }
        const { currentValue, previousValue } = changes.taskData;
        const keys1 = currentValue ? Object.keys(currentValue) : [];
        const keys2 = previousValue ? Object.keys(previousValue) : [];

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            if (currentValue[key] !== previousValue[key]) {
                return true;
            }
        }

        return false;
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.getIsGLP();
    }

    initLargeAnimalEnabled() {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL)
    }
    /**
     * Sets view defaults for tables
     */
    setTableStates() {
        // For now, these are constant
        this.animal.tasksExpanded = true;
        this.animal.inputsExpanded = true;
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = false;
        this.detailTaskTableOptions.showAnimals = false;
        this.detailTaskTableOptions.showSamples = false;
    }

    private async isNamingActive(): Promise<any> {
        this.animalNamingActive = await this.namingService.isAnimalNamingActive();
    }

    async getAnimalDetails(): Promise<any> {
        if (this.animal && this.animal.C_Material_key > 0) {
            const expands: string[] = [
                "Birth.Mating",
                "Genotype",
                "Material.JobMaterial.Job",
                "Material.Line",
                "Material.MaterialPoolMaterial.MaterialPool",
                "Material.PlateMaterial.Plate",
                "Material.cv_Taxon",
                "Material.CohortMaterial.Cohort",
                "Material.TaskMaterial.TaskInstance.ProtocolTask.Protocol",
                "Material.MaterialExternalSync",
                "cv_AnimalMatingStatus",
                "Permit.Resource",
            ];

            this.originalAlternatePhysicalID = this.animal.AlternatePhysicalID;
            this.originalMicrochipIdentifier = this.animal.Material && this.animal.Material.MicrochipIdentifier;

            try {
                this.setBusy(true);
                await this.animalService.getAnimal(this.animal.C_Material_key, expands);
                // Material Pool History
                const data = await this.animalService.getMaterialPoolHistory(this.animal.C_Material_key)
                this.animal.History = data;
                // Lines
                if (this.animal.Material && this.animal.Material.C_Line_key) {
                    const searchQueryDef: SearchQueryDef = {
                        entity: 'Lines',
                        page: 1,
                        size: 200,
                        sortColumn: 'LineName',
                        sortDirection: 'asc',
                        filter: {
                            LineKey: this.animal.Material.C_Line_key
                        }
                    };

                    const results = await this.searchService.getEntitiesBySearch(searchQueryDef)
                    this.animal.Lines = results.data;
                }

                if (this.animal.Material) {
                    // Observation count
                    const queryDef: QueryDef = {
                        size: 0,
                        filter: {
                            Identifier: this.animal.Material.Identifier
                        }
                    };

                    const clinicalObservationsCount = await this.clinicalService.getClinicalObservationsCount(queryDef);
                    const diagnosticObservationsCount = await this.clinicalService.getDiagnosticObservationsCount(queryDef);

                    this.animal.ClinicalObservationCount = clinicalObservationsCount;
                    this.animal.DiagnosticObservationCount = diagnosticObservationsCount;
                }
                if (this.animal.Material.TaskMaterial) {
                    this.animal.Protocols = uniqueArrayFromPropertyPath(
                        this.animal.Material.TaskMaterial,
                        'TaskInstance.ProtocolTask.Protocol.ProtocolName'
                    );
                }
                this.originalPermitKey = this.animal.C_Permit_key;
                this.originalDateOnPermit = this.animal.DateOnPermit;
            } finally {
                this.setBusy(false);
            }
        }

        return Promise.resolve(this.animal);
    }

    private async getAnimalTasks(): Promise<any> {
        const taskMaterials = await this.animalService.getTaskMaterials(this.animal.C_Material_key);
        return this.attachInputEnumerationsToTaskMaterials(taskMaterials);
    }

    private async attachInputEnumerationsToTaskMaterials(taskMaterials: any[]): Promise<any> {
        const promises = [];

        for (const taskMaterial of taskMaterials) {
            const promise = this.enumerationService.attachInputEnumerations(taskMaterial.TaskInput);
            promises.push(promise);
        }

        return Promise.all(promises);
    }

    private getCVs(): Promise<any> {
        const p1 = this.animalVocabService.animalStatuses$.pipe(tap((animalStatuses) => {
            this.animalStatuses = animalStatuses;
        })).toPromise();

        const p2 = this.animalVocabService.animalUses$.pipe(tap((animalUses) => {
            this.animalUses = animalUses;
        })).toPromise();

        const p3 = this.animalVocabService.breedingStatuses$.pipe(tap((breedingStatuses) => {
            this.breedingStatuses = breedingStatuses;
        })).toPromise();

        const p4 = this.animalVocabService.diets$.pipe(tap((diets) => {
            this.diets = diets;
        })).toPromise();

        const p5 = this.animalVocabService.exitReasons$.pipe(tap((exitReasons) => {
            this.exitReasons = exitReasons;
        })).toPromise();

        const p6 = this.animalVocabService.generations$.pipe(tap((generations) => {
            this.generations = generations;
        })).toPromise();

        const p7 = this.animalVocabService.materialOrigins$.pipe(tap((materialOrigins) => {
            this.materialOrigins = materialOrigins;
        })).toPromise();

        const p8 = this.animalVocabService.sexes$.pipe(tap((sexes) => {
            this.sexes = sexes;
        })).toPromise();

        const p9 = this.animalVocabService.iacucProtocols$.pipe(tap((iacucProtocols) => {
            this.iacucProtocols = iacucProtocols;
        })).toPromise();

        const p10 = this.animalVocabService.physicalMarkerTypes$.pipe(tap((physicalMarkerTypes) => {
            this.physicalMarkerTypes = physicalMarkerTypes;
        })).toPromise();

        const p11 = this.animalVocabService.animalCommentStatuses$.pipe(tap((animalCommentStatuses) => {
            this.animalCommentStatuses = animalCommentStatuses;
        })).toPromise();

        const p12 = this.workflowVocabService.workflowAnimalTasks$.pipe(tap((workflowAnimalTasks) => {
            this.animalWorkflowTasks = workflowAnimalTasks;
        })).toPromise();

        const p13 = this.workflowVocabService.workflowJobTasks$.pipe(tap((workflowJobTasks) => {
            this.jobWorkflowTasks = workflowJobTasks;
        })).toPromise();

        const p14 = this.workflowVocabService.taskStatuses$.pipe(tap((taskStatuses) => {
            this.taskStatusKeys = taskStatuses.map((taskStatus) => taskStatus.C_TaskStatus_key);
            this.endStateTaskStatusKeys = taskStatuses.filter((taskStatus) => taskStatus.IsEndState).map((taskStatus) => taskStatus.C_TaskStatus_key);
        })).toPromise();

        const p15 = this.jobVocabService.jobTypes$.pipe(tap((jobTypes) => {
            this.jobTypes = jobTypes;
        })).toPromise();

        const p16 = this.jobVocabService.jobStatuses$.pipe(tap((jobStatuses) => {
            this.jobStatuses = jobStatuses;
        })).toPromise();

        const p17 = this.animalVocabService.animalClassifications$
            .pipe(tap((animalClassifications) => {
                this.animalClassifications = animalClassifications;
            })).toPromise();

        const p18 = this.animalVocabService.animalMatingStatuses$
            .pipe(tap((animalMatingStatuses) => {
                this.animalMatingStatuses = animalMatingStatuses;
            })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18]);
    }

    onCancel() {
        this.animalService.cancelAnimal(this.animal);
    }

    async saveEntity() {
        const errorMessage = await this.validate();
        if (errorMessage) {
            this.validationErrorsPresent = true;
            this.loggingService.logError(this.saveChangesService.generateSaveErrorMessage(this.facet.FacetName, this.facetView, errorMessage), null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        this.validationErrorsPresent = false;
        await this.animalSaveService.save(this.animal);
    }

    async validate(): Promise<string> {
        const translatedAnimal = this.translationService.translate('Animal');

        if (this.animalNamingActive && testBreezeIsNew(this.animal)) {
            const invalidField = await this.animalService.validateAnimalNamingField(this.animal);
            if (invalidField) {
                return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
            }
        } else if (empty(this.animal.AnimalName)) {
            return `A ${translatedAnimal} requires a Name.`;
        }

        // Check that animal has a line
        if (!this.animal.Material.C_Line_key) {
            return `An ${translatedAnimal} requires a ${this.translationService.translate('Line')}.`;
        }

        // Check that each animal comment is not blank and has a status
        for (const comment of this.animal.AnimalComment) {
            if (empty(comment.Comment)) {
                return `${translatedAnimal} Comments can not be blank.`;
            }

            if (empty(comment.C_AnimalCommentStatus_key)) {
                return `${translatedAnimal} Comments require a Status.`;
            }
        }

        // Check that unique fields are valid if isGLP is true
        if (this.isGLP && !this.areGLPUniqueFieldsValid()) {
            return `An ${translatedAnimal}'s Microchip ID and Alternate Physical ID must be unique.`;
        }

        let requiredFields = this.requiredFields;
        if (this.animal.cv_PhysicalMarkerType?.PhysicalMarkerType === this.rapIdMarkerType) {
            requiredFields = this.requiredFields.filter(a => a !== this.physicalMarkerTypeKey);
        }
        const message = this.eventHistories.map(item => item.validate?.()).find(msg => msg)
            || this.characteristicInputs.map(item => item.validate?.()).find(msg => msg)
            || this.detailTaskTables.map(item => item.validate?.()).find(msg => msg)
            || dateControlValidator(this.dateControls)
            || await this.settingService.validateRequiredFields(requiredFields, this.animal, 'animal');
        if (message) {
            return message;
        }

        return '';
    }

    onSaveSuccessful() {
        this.loggingService.logDebug('Successful save reported in Animal Details', null, this.COMPONENT_LOG_TAG);
        this.originalMicrochipIdentifier = this.animal.Material.MicrochipIdentifier;
        this.originalAlternatePhysicalID = this.animal.AlternatePhysicalID;
        this.filterWorkflowTasks();
        this.onJobSelect();
        this.onAnimalSelect();
    }

    private async syncOutputValues(taskOutput: TaskOutput): Promise<void> {
        // Selected output value changed: update values of the selected output
        if (this.selectedJobTaskOutputKeys.includes(taskOutput.Output.C_Output_key)) {
            this.outputChanged(taskOutput, TaskType.Job);
            this.setJobTasksOutputs();
        } else if (this.selectedAnimalTaskOutputKeys.includes(taskOutput.Output.C_Output_key)) {
            this.outputChanged(taskOutput, TaskType.Animal);
            await this.setAnimalTasksOutputs();
        }
        // Hidden output value changed: update task outputs
        else if (this.taskData?.taskType === TaskType.Job) {
            this.setJobTasksOutputs();
        } else if (this.taskData?.taskType === TaskType.Animal) {
            await this.setAnimalTasksOutputs();
        }
    }

    private async syncTaskStateChange(args: TaskStatusChangedEventArgs): Promise<void> {
        const hasTaskJobType = args.taskTypes.some(type => type === TaskType.Job);
        const hasTaskAnimalType = args.taskTypes.some(type => type === TaskType.Animal);
        if (hasTaskJobType) {
            // State of job task instances changed: force output reloading because only completed task instances are handled
            this.jobTasks = [];
            this.onJobSelect();
        } else if (hasTaskAnimalType) {
            // State of animal task instances changed: force output reloading because only completed task instances are handled
            this.animalTasks = [];
            this.tasksLoading = true;

            await this.onTaskSelect(this.animalTasks, this.animalWorkflowTaskKeys, this.animalWorkflowTasks);
            await this.setAnimalTasksOutputs();
    
            this.selectedAnimalTaskOutputKeys = intersectionWith(this.selectedAnimalTaskOutputKeys, this.animalTasksOutputs, (tok, to) => {
                return tok === to.C_Output_key;
            });
            this.tasksLoading = false;

            if (this.isSyncItem) {
                await scrollToElement('.animal-outputs');
            }
        }
    }

    openCageCardModal(cagecardmodal: TemplateRef<any>) {
        this.selectedAnimals = [this.animal];
        this.modalService.open(cagecardmodal);
    }

    statusChanged(animal: any): void {
        this.animalService.statusChangePostProcess(animal);
    }

    /**
     * TODO: Make better code to share this logic,
     *  and other animal logic like it.
     */
    lineChanged() {
        const expands = [
            'cv_Taxon'
        ];
        if (this.animal.Material.C_Line_key) {
            this.lineService.getLine(this.animal.Material.C_Line_key, expands).then((line) => {
                if (this.animal.Material.C_Line_key && line) {
                    this.animal.Material.cv_Taxon = line.cv_Taxon;
                }
            });
            this.namingService.isAnimalNamingActive().then((isNamingActive: boolean) => {
                if (isNamingActive) {
                    this.updateAnimalName('line');
                }
            });
        } else {
            this.animal.Material.cv_Taxon = undefined;
        }
    }

    // Tasks
    addTaskMaterial(taskInstance: any) {
        const taskSequence = maxSequence(this.animal.Material.TaskMaterial) + 1;
        const initialValues: any = {
            C_Material_key: this.animal.C_Material_key,
            C_TaskInstance_key: taskInstance.C_TaskInstance_key,
            Sequence: taskSequence
        };

        this.taskService.createTaskMaterial(initialValues);
    }

    addedProtocol(protocol: any) {
        let taskInstances = uniqueArrayFromPropertyPath(
            this.animal,
            'Material.TaskMaterial.TaskInstance'
        );
        taskInstances = taskInstances.filter((item) => {
            return item.C_Protocol_key === protocol.C_Protocol_key;
        });
        const protocolDateCalculator = new ProtocolDateCalculator();

        for (const taskInstance of taskInstances) {
            protocolDateCalculator.scheduleMaterialDueDates(
                taskInstances,
                taskInstance
            );
        }
    }

    viewAuditReport() {
        this.viewAnimalAuditReportComponentService.openComponent(
            this.animal.C_Material_key
        );
    }

    // <select> formatters
    jobStatusKeyFormatter = (value: any) => {
        return value.C_JobStatus_key;
    }
    jobStatusFormatter = (value: any) => {
        return value.JobStatus;
    }
    jobTypeKeyFormatter = (value: any) => {
        return value.C_JobType_key;
    }
    jobTypeFormatter = (value: any) => {
        return value.JobType;
    }
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    breedingStatusKeyFormatter = (value: any) => {
        return value.C_BreedingStatus_key;
    }
    breedingStatusFormatter = (value: any) => {
        return value.BreedingStatus;
    }
    animalClassificationKeyFormatter = (value: any) => {
        return value.C_AnimalClassification_key;
    }
    animalClassificationFormatter = (value: any) => {
        return value.AnimalClassification;
    }
    animalUseKeyFormatter = (value: any) => {
        return value.C_AnimalUse_key;
    }
    animalUseFormatter = (value: any) => {
        return value.AnimalUse;
    }
    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: any) => {
        return value.Sex;
    }
    generationKeyFormatter = (value: any) => {
        return value.C_Generation_key;
    }
    generationFormatter = (value: any) => {
        return value.Generation;
    }
    dietKeyFormatter = (value: any) => {
        return value.C_Diet_key;
    }
    dietFormatter = (value: any) => {
        return value.Diet;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }
    iacucProtocolKeyFormatter = (value: any) => {
        return value.C_IACUCProtocol_key;
    }
    iacucProtocolFormatter = (value: any) => {
        return value.IACUCProtocol;
    }
    physicalMarkerTypeKeyFormatter = (value: any) => {
        return value.C_PhysicalMarkerType_key;
    }
    physicalMarkerTypeFormatter = (value: any) => {
        return value.PhysicalMarkerType;
    }

    outputChanged(to: any, taskType: TaskType) {
        let { xValues, yValues } = to.Output.TaskOutput.reduce((acc: any, taskOutput: any) => {
            if (taskOutput.TaskOutputSet.TaskInstance.TaskMaterial[0].C_Material_key !== this.animal.C_Material_key) {
                return acc;
            }
            const dateComplete = taskOutput.TaskOutputSet.TaskInstance.DateComplete;
            if (taskOutput.OutputValue === '' || taskOutput.OutputValue === null) {
                return acc;
            }
            return {
                xValues: [...acc.xValues, dateComplete || new Date()],
                yValues: [...acc.yValues, this.formatOutputValue(taskOutput.OutputValue)]
            };
        }, { xValues: [], yValues: [] });

        // Use empty arrays when task output values have been reset in bulk
        xValues = xValues ?? [];
        yValues = yValues ?? [];

        if (taskType === TaskType.Animal) {
            // Update chart values
            this.chartAnimalOutputs = this.chartAnimalOutputs
                .map(j => j.C_Output_key === to.Output.C_Output_key ? { ...j, xValues, yValues } : j)
                .filter(to => to.xValues.length && to.yValues.length);

            // Update the original collection of taskOutput (is used in onAnimalOutputSelect).
            // Needed to have actual xValues and yValues of outputs that are not shown in chart yet.
            this.animalTasksOutputs = this.animalTasksOutputs
                .map(j => j.C_Output_key === to.Output.C_Output_key ? { ...j, xValues, yValues } : j)
                .filter(to => to.xValues.length && to.yValues.length);

            // Update selected outputs to shown in chart
            this.selectedAnimalTaskOutputKeys = intersectionWith(this.selectedAnimalTaskOutputKeys, this.animalTasksOutputs, (tok, to) => {
                return tok === to.C_Output_key;
            });

        } else if (taskType === TaskType.Job) {
            // Update chart values
            this.chartJobOutputs = this.chartJobOutputs
                .map(j => j.C_Output_key === to.Output.C_Output_key ? { ...j, xValues, yValues } : j)
                .filter(to => to.xValues.length && to.yValues.length);

            // Update the original collection of taskOutput (it is used in onJobOutputSelect). 
            // Needed to have actual xValues and yValues of outputs that are not shown in chart yet.
            this.jobTasksOutputs = this.jobTasksOutputs
                .map(j => j.C_Output_key === to.Output.C_Output_key ? { ...j, xValues, yValues } : j)
                .filter(to => to.xValues.length && to.yValues.length);

            // Update selected outputs to shown in chart
            this.selectedJobTaskOutputKeys = intersectionWith(this.selectedJobTaskOutputKeys, this.jobTasksOutputs, (tok, to) => {
                return tok === to.C_Output_key;
            });
        }

        // For some reason, we get ExpressionChangedAfterItHasBeenCheckedError when in bulk remove values for the output that is shown in the chart.
        this.cdRef.detectChanges();
    }

    async onAnimalSelect(includeAllTaskStatusKeys = false): Promise<void> {
        this.setBusy(true);

        await this.onTaskSelect(this.animalTasks, this.animalWorkflowTaskKeys, this.animalWorkflowTasks);
        await this.setAnimalTasksOutputs();

        this.selectedAnimalTaskOutputKeys = intersectionWith(this.selectedAnimalTaskOutputKeys, this.animalTasksOutputs, (tok, to) => {
            return tok === to.C_Output_key;
        });
        
        this.setBusy(false);

        if (this.isSyncItem) {
            await scrollToElement('.animal-outputs');
        }
    }

    private async setAnimalTasksOutputs(): Promise<void> {
        this.animalTasksOutputs = await (this.animalTasks.length ? this.setOutputs(this.animalTasks) : Promise.resolve([]));
        this.onAnimalOutputSelect();
    }

    onAnimalOutputSelect(): void {
        this.chartAnimalOutputs = this.animalTasksOutputs.filter((to: any) => this.selectedAnimalTaskOutputKeys.includes(to.C_Output_key));
        this.saveSelectionConfig();
    }

    async onJobSelect() {
        this.setBusy(true);

        await this.onTaskSelect(this.jobTasks, this.jobWorkflowTaskKeys, this.jobWorkflowTasks);
        await this.setJobTasksOutputs();

        this.selectedJobTaskOutputKeys = intersectionWith(this.selectedJobTaskOutputKeys, this.jobTasksOutputs, (tok: any, to: any) => {
            return tok === to.C_Output_key;
        });

        this.setBusy(false);

        if (this.isSyncItem) {
            await scrollToElement('.study-outputs');
        }

    }

    public onPermitSelect(permitKey: number): void {
        if (!permitKey) {
            this.animal.DateOnPermit = null;
            return;
        }
        if (permitKey === this.originalPermitKey) {
            this.animal.DateOnPermit = this.originalDateOnPermit;
            return;
        }
        this.permitService.getPermit(permitKey, ['Resource']);
        this.animal.DateOnPermit = new Date();
    }

    private async setJobTasksOutputs(): Promise<void> {
        this.jobTasksOutputs = await (this.jobTasks.length ? this.setOutputs(this.jobTasks) : Promise.resolve([]));
        this.onJobOutputSelect();
    }

    onJobOutputSelect() {
        this.chartJobOutputs = this.jobTasksOutputs.filter((to: any) => this.selectedJobTaskOutputKeys.includes(to.C_Output_key));
        this.saveSelectionConfig();
    }

    formatOutputValue(outputValue: any) {
        return Math.round((+outputValue + Number.EPSILON) * 100) / 100;
    }

    async setOutputs(tasks: any[]): Promise<ITaskOutput[]> {
        const dataTypes = [DataType.INHERITED_MOST_RECENT, DataType.INHERITED_FIRST_OCCURRENCE, DataType.NUMBER,
            DataType.INHERITED_SECOND_MOST_RECENT, DataType.INHERITED_THIRD_MOST_RECENT, DataType.CALCULATED];
        
        const taskOutputs: ITaskOutput[] = [];
        const taskKeys = tasks.flatMap(task => task.taskKeys);
        if (taskKeys.length) {
            const taskOutputSets = await this.animalService.fetchTasks(taskKeys);
            for (const tos of taskOutputSets) {
                for (const to of tos.TaskOutput) {
                    if (to.OutputValue !== '' && to.OutputValue !== null && dataTypes.includes(to.Output.cv_DataType.DataType)) {
                        const index = taskOutputs.findIndex((jto: any) => jto.C_Output_key === to.C_Output_key);
                        if (index < 0) {
                            taskOutputs.push({
                                C_Output_key: to.C_Output_key,
                                OutputName: to.Output.OutputName,
                                C_WorkflowTask_key: to.Output.C_WorkflowTask_key,
                                xValues: [tos.TaskInstance.DateComplete || new Date()],
                                yValues: [this.formatOutputValue(to.OutputValue)],
                                completed: [tos.TaskInstance.cv_TaskStatus.IsEndState]
                            });
                        } else {
                            taskOutputs[index].xValues.push(tos.TaskInstance.DateComplete || new Date());
                            taskOutputs[index].yValues.push(this.formatOutputValue(to.OutputValue));
                            taskOutputs[index].completed.push(tos.TaskInstance.cv_TaskStatus.IsEndState)
                        }
                    }
                }
            }
            return taskOutputs;
        }
        return Promise.resolve(taskOutputs);
    }

    async onTaskSelect(selectedTasks: any, workflowTaskKeys: any, workflowTasks: any) {
        if (workflowTaskKeys.length < 1) { // remove existing tables if there are no selected tasks
            selectedTasks.length = 0;
            return;
        }

        const workflowKeys: any = []; // workflow tasks in addition to the pre-selected tasks
        const taskNames: any = [];
        workflowTaskKeys.forEach((taskKey: any) => {
            const task = workflowTasks.find((t: any) => t.C_WorkflowTask_key === taskKey);
            if (!task) {
                return;
            }
            const taskName = task.TaskName;
            taskNames.push(taskName);
            if (selectedTasks.findIndex((item: any) => item.name === taskName) < 0) {
                workflowKeys.push(taskKey);
            }
        });

        let index = selectedTasks.length;
        while (index--) { // remove all existing tables for which tasks are unselected
            if (!taskNames.includes(selectedTasks[index].name)) {
                selectedTasks.splice(index, 1);
            }
        }

        if (workflowKeys.length > 0) { // only make api call for additional tasks selected
            const filter = this.processFilter(workflowKeys);
            const data = await this.loadWorkflowList(filter);
            const endStateTaskKeys = data.filter((task: any) => task.IsEndState).map((task: any) => task.TaskKey);

            data.forEach((row: any) => {
                const foundTask = selectedTasks.find((item: any) => item.name === row.TaskName);
                if (!foundTask) {
                    selectedTasks.push({name: row.TaskName, endStateTaskKeys, taskKeys: [row.TaskKey], randomId: randomId()});
                } else {
                    foundTask.taskKeys.push(row.TaskKey);
                    foundTask.endStateTaskKeys = endStateTaskKeys;
                }
            });
        } else {
            return;
        }
    }

    trackRow = (index: number, item: any): string => {
        return `${item}-${index}`;
    }

    expand: { [index: string]: boolean } = {
        outputs: false,
        studies: false,
        animals: false
    };

    resetOutputs() {
        this.expand = {
            outputs: false,
            studies: false,
            animals: false
        };
        this.animalTasks.length = 0;
        this.jobTasks.length = 0;
        this.onAnimalSelect();
        this.onJobSelect();
    }

    processFilter(workflowTaskKeys: any) {
        const filter: any = {};
        filter.UtcOffset = new Date().getTimezoneOffset() / 60;
        filter.TaskStatusKeys = this.taskStatusKeys;
        filter.WorkflowTaskKeys = workflowTaskKeys;
        filter.MaterialKeys = [this.animal.C_Material_key];

        return filter;
    }

    async loadWorkflowList(filter: any): Promise<any> {
        const workflowTableOptions = new WorkflowTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isGLP,
            this.dateFormatterService
        );

        const visibleColumns = workflowTableOptions.options.columns
            .filter((column) => column.visible !== false && column.field !== 'TaskCount')
            .map((column) => column.field);

        visibleColumns.push('TaskName');

        const queryDef: APIQueryDef = {
            page: 1,
            size: 50,
            sort: 'DateDue DESC',
            filter,
            columns: [JSON.stringify(visibleColumns)]
        };

        const response = await this.workflowService.getAPITasks(queryDef);
        return response.results;
    }

    /**
     * Parse the TaskSelectionConfig JSON string, or provide a blank config object
     */
    parseSelectionConfig(): TaskSelectionConfig[] {
        try {
            // using BulkDataConfiguration to avoid new field
            if (this.facet.BulkDataConfiguration) {
                return JSON.parse(this.facet.BulkDataConfiguration);
            }
        } catch (e) {
            console.error('Could not parse BulkDataConfiguration', e);
        }

        return [new TaskSelectionConfig(), new TaskSelectionConfig()];
    }

    /**
     * Save the task selections for this facet.
     */
    saveSelectionConfig() {
        const selectConfig = this.parseSelectionConfig();

        const animalConfig = new TaskSelectionConfig();
        animalConfig.type = "animal";
        animalConfig.keys = this.animalWorkflowTaskKeys;
        animalConfig.outputKeys = this.selectedAnimalTaskOutputKeys;
        const studyConfig = new TaskSelectionConfig();
        studyConfig.type = "study";
        studyConfig.keys = this.jobWorkflowTaskKeys;
        studyConfig.outputKeys = this.selectedJobTaskOutputKeys;

        // Save all task output configurations
        if (selectConfig[0] && selectConfig[0].keys && selectConfig[0].outputKeys.length > 0) {
            this.animalService.fetchOutputs(selectConfig[0].outputKeys).then((outputs: any) => {
                animalConfig.outputKeys = outputs.filter((output: any) => !this.animalWorkflowTaskKeys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                for (const key of this.selectedAnimalTaskOutputKeys) {
                    if (!animalConfig.outputKeys.includes(key)) {
                        animalConfig.outputKeys.push(key);
                    }
                }
            }).then(() => {
                // Rebuild the BulkDataConfiguration JSON
                const saveConfig = [animalConfig, studyConfig];
                this.facet.BulkDataConfiguration = JSON.stringify(saveConfig);
            });
        }

        if (selectConfig[1] && selectConfig[1].keys && selectConfig[1].outputKeys.length > 0) {
            this.animalService.fetchOutputs(selectConfig[1].outputKeys).then((outputs: any) => {
                studyConfig.outputKeys = outputs.filter((output: any) => !this.jobWorkflowTaskKeys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                for (const key of this.selectedJobTaskOutputKeys) {
                    if (!studyConfig.outputKeys.includes(key)) {
                        studyConfig.outputKeys.push(key);
                    }
                }
            }).then(() => {
                // Rebuild the BulkDataConfiguration JSON
                const saveConfig = [animalConfig, studyConfig];
                this.facet.BulkDataConfiguration = JSON.stringify(saveConfig);
            });
        } else {
            const saveConfig = [animalConfig, studyConfig];
            this.facet.BulkDataConfiguration = JSON.stringify(saveConfig);
        }
    }

    private async setupSyncItem(): Promise<void> {
        this.expand.outputs = true;
        this.expand.animals = false;
        this.expand.studies = false;

        this.animalWorkflowTaskKeys = [];
        this.selectedAnimalTaskOutputKeys = [];
        this.animalTasks = [];
        this.animalTasksOutputs = [];
        this.chartAnimalOutputs = [];

        this.jobWorkflowTaskKeys = [];
        this.jobTasks = [];
        this.jobTasksOutputs = [];
        this.selectedJobTaskOutputKeys = [];
        this.chartJobOutputs = [];

        const selectConfig = this.parseSelectionConfig();

        if (this.taskData.taskType === TaskType.Animal) {
            this.expand.animals = true;
            this.animalWorkflowTaskKeys = [this.taskData.workflowTaskKey];
            if (selectConfig[0] && selectConfig[0].keys && selectConfig[0].outputKeys.length > 0) {
                this.animalService.fetchOutputs(selectConfig[0].outputKeys).then((outputs: any) => {
                    this.selectedAnimalTaskOutputKeys = outputs.filter((output: any) => this.animalWorkflowTaskKeys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                    this.onAnimalSelect(true);
                });
            } else {
                this.onAnimalSelect(true);
            }
        }
        if (this.taskData.taskType === TaskType.Job) {
            this.expand.studies = true;
            this.jobWorkflowTaskKeys = [this.taskData.workflowTaskKey];
            if (selectConfig[1] && selectConfig[1].keys && selectConfig[1].outputKeys.length > 0) {
                this.animalService.fetchOutputs(selectConfig[1].outputKeys).then((outputs: any) => {
                    this.selectedJobTaskOutputKeys = outputs.filter((output: any) => this.jobWorkflowTaskKeys.includes(output.C_WorkflowTask_key)).map((res: any) => res.C_Output_key);
                    this.onJobSelect();
                });
            } else {
                this.onJobSelect();
            }
        }
    }

    updateAnimalName(field: string) {
        // Apply new number only if is an update
        if (this.animal.AnimalName) {
            this.animalService.getAnimalPrefixField().then((animalPrefixField: string) => {
                if (animalPrefixField.toLowerCase() === field.toLowerCase()) {
                    // Automatically regenerate AnimalName
                    this.animalService.autoGenerateAnimalName(this.animal).then((newName: string) => {
                        if (newName !== this.animal.AnimalName) {
                            this.animal.AnimalName = newName;
                            // Alert user of automatic change
                            this.loggingService.logWarning(
                                `The Name field has been automatically changed due to changing the ${this.translationService.translate(animalPrefixField)} field.`,
                                null, this.COMPONENT_LOG_TAG, true);
                        }
                    });
                }
            });
        }
    }

    setBusy(isBusy = true) {
        this.busy += isBusy ? 1 : -1;
        this.setLoading(this.busy > 0);
    }

    areGLPUniqueFieldsValid(): boolean {
        const animalMicrochipIdentifierErrors = this.animalForm.controls.animalMicrochipIdentifier?.errors;
        if (animalMicrochipIdentifierErrors && animalMicrochipIdentifierErrors.unique && this.animal.Material.MicrochipIdentifier !== this.originalMicrochipIdentifier) {
            this.loggingService.logWarning(
                'The Microchip ID is already in use. Please enter a new ID and try again.', 'Validation Error', this.COMPONENT_LOG_TAG, false);
            return false;
        }

        const alternatePhysicalIDErrors = this.animalForm.controls.alternatePhysicalID?.errors;
        if (alternatePhysicalIDErrors && alternatePhysicalIDErrors.unique && this.animal.AlternatePhysicalID !== this.originalAlternatePhysicalID) {
            this.loggingService.logWarning(
                'The Alternate Physical ID is already in use. Please enter a new ID and try again.', 'Validation Error', this.COMPONENT_LOG_TAG, false);
            return false;
        }
        return true;
    }

    getSocialPartners(material: any): any {
        if (material.MaterialPool.SocialGroupMaterial.length === 0) {
            return null;
        } else {
            let result = material.MaterialPool.SocialGroupMaterial.filter((socialGroupMaterial: any) => socialGroupMaterial.DateOut === null);
            result = result.sort((a: any, b: any) => new Date(b.DateIn).getTime() - new Date(a.DateIn).getTime());
            result = result.map((socialGroupMaterial: any) => socialGroupMaterial.Material.Animal.AnimalName);
            return result.join(',');
        }
    }

    hasChanges(): boolean {
        return this.animalSaveService.hasChanges(this.animal);
    }

    // TODO: Currently overriden base exitClicked for make it working for backward compatibility.
    //  Eventually it should be placed in base-detail.ts
    async exitClicked() {
        const validationPassed = await this.handleUnsavedChangesOnExit();
        if (validationPassed) {
            this.exit.emit();
        }
    }

    // TODO: Currently overriden base nextClicked for make it working for backward compatibility.
    //  Eventually it should be placed in base-detail.ts
    async nextClicked() {
        const validationPassed = await this.handleUnsavedChangesOnExit();
        if (validationPassed) {
            this.next.emit();
        }
    }

    // TODO: Currently overriden base nextClicked for make it working for backward compatibility.
    //  Eventually it should be placed in base-detail.ts
    async previousClicked() {
        const validationPassed = await this.handleUnsavedChangesOnExit();
        if (validationPassed) {
            this.previous.emit();
        }
    }

    /**
     * @returns {Promise<boolean>} true if frontend validation passed, otherwise false
     */
    async handleUnsavedChangesOnExit(): Promise<boolean> {
        if (this.hasChanges()) {
            const result = await this.viewUnsavedChangesModalService.openComponent(this.COMPONENT_LOG_TAG);
            if (result === 'save') {
                await this.saveEntity();
                return !this.validationErrorsPresent;
            } else {
                this.cancelAnyChanges();
            }
        }

        return true;
    }

    // Filters workflow tasks to only display tasks that have associated instances for the animal
    filterWorkflowTasks(){
        const relatedWorkflowTaskKeys = this.animal.Material.TaskMaterial
            .reduce((workflowTaskKeys: Set<number>, taskMaterial: TaskMaterial) => {
                if (taskMaterial.TaskInstance?.cv_TaskStatus?.IsEndState)
                    workflowTaskKeys.add(taskMaterial.TaskInstance.C_WorkflowTask_key);
                return workflowTaskKeys
            }, new Set<number>());

        this.relatedAnimalWorkflowTasks = this.animalWorkflowTasks.filter(workflowTask => relatedWorkflowTaskKeys.has(workflowTask.C_WorkflowTask_key));
        this.relatedJobWorkflowTasks = this.jobWorkflowTasks.filter(workflowTask => relatedWorkflowTaskKeys.has(workflowTask.C_WorkflowTask_key));
        this.jobWorkflowTaskKeys = this.jobWorkflowTaskKeys.filter((workflowTaskKey: number) => relatedWorkflowTaskKeys.has(workflowTaskKey));
        this.animalWorkflowTaskKeys = this.animalWorkflowTaskKeys.filter((workflowTaskKey: number) => relatedWorkflowTaskKeys.has(workflowTaskKey));
    }
}
