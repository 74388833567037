import { CellFormatterService, TableOptions } from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';
import { TranslationService } from '@services/translation.service';

export class AuditPermitSpeciesLinesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: `permit-species-${this.translationService.translate('Line').toLocaleLowerCase()}s-audit.csv`,
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Lines',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Line', 'Line'),
                    cellClass: AuditClassFactory.createCellClass('line'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
            ]
        };
    }
}
